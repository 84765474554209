import { AsyncPipe, NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, EventEmitter, Input, Output, computed } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from "@angular/router";
import {
   BadgeComponent,
   IconComponent,
   ModalService,
   PopoverDirective,
   UpsellPopover,
} from "@limblecmms/lim-ui";
import { filter, type Subscription } from "rxjs";
import { PickAssets } from "src/app/assets/components/pickAssetsModal/pickAssets.modal.component";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { PopPart } from "src/app/parts/components/popPartsModal/popPart.modal.component";
import {
   PartsFacadeService,
   type AddPartModalData,
} from "src/app/parts/components/shared/parts-facade-service/parts-facade-service.service";
import { AlertService } from "src/app/shared/services/alert.service";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { ManageSubscription } from "src/app/subscriptions/services/manageSubscription";
import { StartWOService } from "src/app/tasks/services/start-wo.service";
import { PermissionID } from "src/app/users/schemata/users/self/credentials/permission.enum";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";
import { PickVendors } from "src/app/vendors/components/pickVendorsModal/pickVendors.modal.component";
import { PopVendor } from "src/app/vendors/components/popVendorModal/popVendor.modal.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";

@Component({
   selector: "nav-location",
   templateUrl: "./nav-location.component.html",
   styleUrls: ["./nav-location.component.scss"],
   standalone: true,
   imports: [
      NgClass,
      AsyncPipe,
      BadgeComponent,
      IconComponent,
      PopoverDirective,
      UpsellPopover,
      RouterLink,
   ],
})
export class NavLocationComponent implements OnInit, OnDestroy {
   @Input({ required: true }) public locationID?: number;
   @Input() public isChild: boolean = true;
   @Input() public defaultOpen: boolean = false;
   @Output() public readonly locationActive = new EventEmitter<boolean>();

   protected location;
   protected unfoldPurchasing: boolean = false;
   protected unfoldLoc: boolean = false;
   protected creds?: {
      tasks: boolean;
      myOpenTasks: boolean;
      completedTasks: boolean;
      myCompletedTasks: boolean;
      newTasks: boolean;
      asset: boolean;
      lookAsset: boolean;
      part: boolean;
      lookPart: boolean;
      vendor: boolean;
      lookVendor: boolean;
      team: boolean;
      budget: boolean;
      pos: boolean;
      bills: boolean;
      pm: boolean;
      wr: boolean;
      moveAsset: boolean;
   };
   public activeRouteName?: string | undefined;
   protected showLocation: boolean = true;
   protected purchasingCredCount: number = 0;
   protected featureUnlimitedVendors: boolean = false;
   protected featureUnlimitedParts: boolean = false;
   protected featureUnlimitedPOs: boolean = false;
   protected featureLimitedNumber: boolean = false;
   protected featureMultipleLocations: boolean = false;
   protected featureCustomBudgets: boolean = false;
   protected isBasicPlan: boolean = true;
   private isBasicPlanSub?: Subscription;
   private manageFeatureFlagsSub?: Subscription;
   private readonly routeChangeSubscription: Subscription;

   private readonly manageLocation = inject(ManageLocation);
   private readonly credService = inject(CredService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageSubscription = inject(ManageSubscription);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly router = inject(Router);
   private readonly route = inject(ActivatedRoute);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageVendor = inject(ManageVendor);
   private readonly alertService = inject(AlertService);
   private readonly partsFacadeService = inject(PartsFacadeService);
   private readonly startWOService = inject(StartWOService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.routeChangeSubscription = this.router.events
         .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
         .subscribe(() => {
            const routeLocationID =
               this.route.firstChild?.snapshot.paramMap.get("locationID");
            if (Number(routeLocationID) !== this.locationID) {
               this.setActiveRouteName(undefined);
               return;
            }
            assert(this.route.firstChild !== null);
            this.setActiveRouteName(this.route.firstChild.snapshot.data.routeName);
         });
   }

   public ngOnInit(): void {
      if (this.locationID === undefined) {
         throw new Error("Location ID is required");
      }

      if (this.route.firstChild !== null) {
         const routeLocationID =
            this.route.firstChild?.snapshot.paramMap.get("locationID");
         if (Number(routeLocationID) === this.locationID) {
            this.setActiveRouteName(this.route.firstChild.snapshot.data.routeName);
         }
      }
      this.location = this.manageLocation.getLocation(this.locationID);
      this.isBasicPlanSub = this.manageSubscription.isBasicPlan$.subscribe(
         (isBasicPlan) => {
            this.isBasicPlan = isBasicPlan;
            this.getPermissions();
         },
      );

      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(
         (isFeatureEnabledMap: IsFeatureEnabledMap) => {
            this.featureUnlimitedVendors = isFeatureEnabledMap.featureUnlimitedVendors;
            this.featureUnlimitedParts = isFeatureEnabledMap.featureUnlimitedParts;
            this.featureUnlimitedPOs = isFeatureEnabledMap.featureUnlimitedPOs;
            this.featureLimitedNumber = isFeatureEnabledMap.featureLimitedNumber;
            this.featureMultipleLocations = isFeatureEnabledMap.featureMultipleLocations;
            this.featureCustomBudgets = isFeatureEnabledMap.featureCustomBudgets;

            this.getPermissions();
         },
      );
      if (this.creds === undefined) {
         this.getPermissions();
      }

      this.setCollapsableStates();
   }

   public ngOnDestroy(): void {
      this.manageFeatureFlagsSub?.unsubscribe();
      this.isBasicPlanSub?.unsubscribe();
      this.routeChangeSubscription.unsubscribe();
   }

   public toggleLocationActive(): void {
      if (this.location.inactive && !this.featureMultipleLocations) return;
      this.unfoldLoc = !this.unfoldLoc;
      if (this.manageUser.getCurrentUser().userInfo.customerID == 369) {
         this.router.navigate(["/assetManagement", this.locationID]);
      }
   }

   private getPermissions(): void {
      assert(this.locationID !== undefined);
      const currentUser = this.manageUser.getCurrentUser();
      const isSuperUser = currentUser.userInfo.isSuperUser ?? false;
      this.creds = {
         tasks: false,
         myOpenTasks: false,
         completedTasks: false,
         myCompletedTasks: false,
         newTasks: false,
         asset: false,
         lookAsset: false,
         part: false,
         lookPart: false,
         vendor: false,
         lookVendor: false,
         team: false,
         budget: false,
         pos: false,
         bills: false,
         pm: false,
         wr: false,
         moveAsset: false,
      };
      this.creds.tasks = this.credService.isAuthorized(
         this.locationID,
         PermissionID.ViewAllOpenTasks,
      );
      this.creds.myOpenTasks = this.credService.isAuthorized(
         this.locationID,
         PermissionID.ViewMyOpenTasks,
      );
      this.creds.completedTasks = this.credService.isAuthorized(
         this.locationID,
         PermissionID.ViewAllCompletedTasks,
      );
      this.creds.myCompletedTasks = this.credService.isAuthorized(
         this.locationID,
         PermissionID.ViewMyCompletedTasks,
      );
      this.creds.newTasks = this.credService.isAuthorized(
         this.locationID,
         PermissionID.StartNewTasks,
      );
      this.creds.asset = this.credService.isAuthorized(
         this.locationID,
         PermissionID.ViewManageAssets,
      );
      this.creds.moveAsset = this.credService.isAuthorized(
         this.locationID,
         PermissionID.ChangeAssetHierarchy,
      );
      this.creds.pm = this.credService.isAuthorized(
         this.locationID,
         PermissionID.ViewManagePMs,
      );
      this.creds.wr = this.credService.isAuthorized(
         this.locationID,
         PermissionID.ReportAProblem,
      );
      this.creds.lookAsset = this.credService.isAuthorized(
         this.locationID,
         PermissionID.ViewLookupAnAsset,
      );
      this.creds.part =
         this.isBasicPlan ||
         ((this.featureUnlimitedParts || this.featureLimitedNumber) &&
            this.credService.isAuthorized(
               this.locationID,
               PermissionID.ViewManageParts,
            )) ||
         (!this.featureUnlimitedParts && !this.featureLimitedNumber && isSuperUser);
      this.creds.lookPart =
         !this.creds.part &&
         (this.featureUnlimitedParts || this.featureLimitedNumber) &&
         this.credService.isAuthorized(this.locationID, PermissionID.ViewLookupAPart);
      this.creds.vendor =
         this.isBasicPlan ||
         ((this.featureUnlimitedVendors || this.featureLimitedNumber) &&
            this.credService.isAuthorized(
               this.locationID,
               PermissionID.ViewManageVendors,
            )) ||
         (!this.featureUnlimitedVendors && !this.featureLimitedNumber && isSuperUser);
      this.creds.lookVendor =
         !this.creds.vendor &&
         (this.featureUnlimitedVendors || this.featureLimitedNumber) &&
         this.credService.isAuthorized(this.locationID, PermissionID.ViewLookUpAVendor);
      this.creds.team = this.credService.isAuthorized(
         this.locationID,
         PermissionID.ViewManageTeams,
      );
      this.creds.budget =
         this.isBasicPlan ||
         ((this.featureUnlimitedPOs || this.featureLimitedNumber) &&
            this.credService.isAuthorized(this.locationID, PermissionID.ManageBudgets)) ||
         (!this.featureUnlimitedPOs && !this.featureLimitedNumber && isSuperUser);
      this.creds.pos =
         this.isBasicPlan ||
         ((this.featureUnlimitedPOs || this.featureLimitedNumber) &&
            this.credService.isAuthorized(this.locationID, PermissionID.ManagePOs)) ||
         (!this.featureUnlimitedPOs && !this.featureLimitedNumber && isSuperUser);
      this.creds.bills =
         this.isBasicPlan ||
         ((this.featureUnlimitedPOs || this.featureLimitedNumber) &&
            this.credService.isAuthorized(this.locationID, PermissionID.ManageBills)) ||
         (!this.featureUnlimitedPOs && !this.featureLimitedNumber && isSuperUser);

      this.purchasingCredCount = [
         this.creds.budget,
         this.creds.pos,
         this.creds.bills,
      ].reduce((acc, curr) => {
         return curr ? acc + 1 : acc + 0;
      }, 0);

      if (this.location?.inactive && !isSuperUser) {
         this.showLocation = false;
      }
   }

   public toggleUnfoldPurchasing(): void {
      this.unfoldPurchasing = !this.unfoldPurchasing;
   }

   public async lookUpAsset(): Promise<void> {
      assert(this.lang() !== undefined);
      const modalRef = this.modalService.open(PickAssets);
      const instance = modalRef.componentInstance;
      instance.message = "";
      instance.title = this.lang().lookUpAnAsset;
      instance.singleLocation = this.locationID ?? 0;
      instance.selectOne = true;
      instance.restrictToCred = false;
      instance.iDontKnowOption = false;
      instance.showMoveAssetButton = this.creds?.moveAsset ?? false;

      const asset = await modalRef.result;
      if (!asset) return;
      const fullAsset = this.manageAsset.getAsset(asset.assetID);
      if (!fullAsset) {
         return;
      }
      const instance2 = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance2,
         resolve: {
            assetID: fullAsset.assetID,
            locationID: fullAsset.locationID,
            data: {
               restrict: false,
            },
         },
      };
   }

   public async lookUpPart(): Promise<void> {
      assert(this.lang() !== undefined);

      const modalData: AddPartModalData = {
         title: this.lang().LookupPart,
         message: this.lang().LookupPartMsg,
         locationID: this.locationID,
         selectOne: true,
         buttonText: this.lang().LookupPart,
      };

      const parts = await this.partsFacadeService.openAddPartModal(modalData);

      if (!parts) return;

      const instance = this.modalService.open(PopPart);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            partID: parts[0].partID,
            locationID: parts[0].locationID,
            data: {
               restrict: false,
            },
         },
      };
   }

   public async lookUpVendor(): Promise<void> {
      assert(this.lang() !== undefined);
      const instance = this.modalService.open(PickVendors);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().lookUpAVendor,
            data: {
               singleLocation: this.locationID,
               selectOne: true,
               restrictToCred: false,
               iDontKnowOption: false,
               locationID: this.locationID,
            },
         },
      };
      const vendorIDArray = await instance.result;
      if (vendorIDArray === 0) {
         return; // User closed pick vendor modal without making a selection
      }
      const vendor = this.manageVendor.getVendor(vendorIDArray[0]);
      assert(vendor);
      const instance2 = this.modalService.open(PopVendor);
      this.paramsService.params = {
         modalInstance: instance2,
         resolve: {
            vendorID: vendor.vendorID,
            locationID: vendor.locationID,
            data: {
               restrict: false,
            },
         },
      };
   }

   public reportProblem(): void {
      assert(this.lang() !== undefined);
      assert(this.locationID !== undefined);
      if (!this.credService.isAuthorized(this.locationID, PermissionID.ReportAProblem)) {
         this.alertService.addAlert(this.lang().cred45Fail, "danger", 10000);
         return;
      }
      const currentUser = this.manageUser.getCurrentUser();
      const code = currentUser.userInfo.customerBarcodeStr;
      this.router.navigate(["/loc-problem", code, this.locationID]);
   }

   public startWorkOrder(): void {
      this.startWOService.startWorkOrder(undefined, this.locationID);
   }

   private setActiveRouteName(routeName: string | undefined): void {
      const oldActiveRouteName = this.activeRouteName;
      this.activeRouteName = routeName;
      if (routeName !== oldActiveRouteName) {
         this.setCollapsableStates();
         this.locationActive.emit(routeName !== undefined);
      }
   }

   private setCollapsableStates(): void {
      this.unfoldLoc = this.activeRouteName !== undefined || this.defaultOpen;
   }
}
