import { makeEnvironmentProviders, type EnvironmentProviders } from "@angular/core";
import { providePrimeNG } from "primeng/config";
import { EmpoweredPreset } from "src/app/shared/empowered/empowered-primeng-theme";
/**
 * This is the Root Provider to set up the empowered library
 */
export function provideEmpowered(): EnvironmentProviders {
   return makeEnvironmentProviders([
      providePrimeNG({
         theme: {
            preset: EmpoweredPreset,
         },
      }),
   ]);
}
