import { inject } from "@angular/core";
import type { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Router } from "@angular/router";
import type { MappedFeatureKey } from "src/app/shared/services/feature-flags/feature-flag-map.types";
import { FeatureFlagService } from "src/app/shared/services/feature-flags/feature-flag.service";

/**
 * Guard to redirect to another route if a flag is not enabled
 *
 * @param flag - Flag needed to proceed with current navigation
 * @param redirectRoute - Route to redirect to if flag is not enabled
 * @param route - This is the ActivatedRouteSnapshot that is passed into the guard
 */
export async function featureFlagGuard(
   flag: MappedFeatureKey,
   redirectRoute: string,
   route: ActivatedRouteSnapshot,
   state: RouterStateSnapshot,
) {
   const featureFlagsService: FeatureFlagService = inject(FeatureFlagService);
   const router: Router = inject(Router);
   const isFlagEnabled = await featureFlagsService.isEnabled(flag);

   const { data } = route;
   const newUrl = state.url.replace(data.routeName, redirectRoute);

   return isFlagEnabled || router.createUrlTree([newUrl]);
}
